<template>
  <div class="row pl-4 pt-0 w-100">
    <h2 class="col-12 mb-4">Ключевые этапы</h2>

    <div v-for="(milestone, index) in milestonesList" :key="index"
      class="card mb-3 overflow-hidden col-12" style="min-width: 12rem">
      <div class="bg-holder bg-card" 
        style="background-image:url(/img/illustrations/corner-3.png);">
      </div>
      <!--/.bg-holder-->
      <div class="card-body position-relative">
        <div class="row">
          <h6 class="col-8">
          {{formatDate(milestone.deadline)}}
          <span v-if="checkDate(milestone.deadline) == 'future'"
            class="badge badge-soft-success rounded-capsule ml-2">
            предстоящее
          </span>
          <span v-if="checkDate(milestone.deadline) == 'past'"
            class="badge badge-soft-danger rounded-capsule ml-2">
            прошедшее
          </span>
          </h6>
          <div class="col-4 p-0 text-right">
            <div class="btn btn-outline-secondary btn-sm px-2 border-0">
              <i class="fas fa-pencil-alt"></i>
            </div>
          </div>
          
          <div class="col-8 d-flex align-items-center display-4 fs-4 font-weight-normal font-proxima"
            style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
            <div class="d-flex align-items-center custom-control custom-checkbox pr-0 mb-2 mr-2">
              <input 
                class="form-check-input" 
                :checked="milestone.finished"
                type="checkbox"
                @change="finishedMilestone(milestone)"
                >
            </div>
            {{milestone.title}}
            
          </div>
          <div class="col-2 p-0 pl-3 border-left-400">
            <div class="small text-400">Ответсвенный</div>
            <div class="font-proxima">{{milestone.person ? milestone.person : '---'}}</div> 
          </div>
          <div class="col-2 d-flex align-items-end justify-content-end p-0 text-right">
            <div class="btn btn-outline-danger btn-sm px-2 border-0"
              @click="deleteMilestone(milestone)">
              <i class="far fa-trash-alt"></i>
            </div> 
          </div>
          <div class="col-8">
            <small style="line-height: 1rem;">{{milestone.desc}}</small>
          </div>
        </div>
        
      </div>
    </div>

    <milestone-edit-modal />
  </div>
</template>

<script>
import moment from 'moment';

import MilestoneEditModal from '../components/app/modals/MilestoneEditModal';

export default {
  data: () =>({
    deleting: false
  }),
  
  components: {
    MilestoneEditModal
  },

  async created() {
    await this.$store.dispatch('fetchMilestonesList');
  },

  computed: {
    milestonesList() {
      return this.$store.getters.getMilestonesList;
    }
  },

  methods: {
    formatDate(date) {
      return date;
    },

    checkDate(date) {
      return moment(date) > moment() ? 'future' : 'past';
    },

    async finishedMilestone(milestone) {
      milestone.finished = !milestone.finished;
      await this.$store.dispatch('updateMilestone', milestone);
    },

    deleteMilestone(milestone) {
      this.$bvModal.msgBoxConfirm(`Вы действительно хотите удалить "${milestone.title}"?`, {
        title: 'Подтвердите своё решение',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Да, удалить',
        cancelTitle: 'Нет',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then(async (agree) => {
        console.log(milestone)
        if (agree) {
          this.deleting = true;
          await this.$store.dispatch('deleteMilestone', milestone);
          this.deleting = false;
        }
      })
      .catch(err => {
          // An error occurred
          window.console.log('unable to delete milestone document: ', err)
      })
    }
  }

  

}
</script>

<style>

</style>